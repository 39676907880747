import React, { useEffect, useRef } from 'react';

const GameCanvas = ({ multiplier, isCrashed }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        const rocketYPosition = canvasHeight / 2;

        const render = () => {
            // Clear the canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            ctx.save();
            ctx.translate(canvasWidth / 2, rocketYPosition);

            // Draw the rocket icon
            ctx.font = "40px Arial";
            ctx.textAlign = "center";
            ctx.fillText("🚀", 0, 0);

            // Draw the multiplier text
            ctx.fillStyle = isCrashed ? "red" : "black";
            ctx.font = "24px Arial";
            ctx.fillText(`${multiplier.toFixed(2)}x`, 0, -40);

            ctx.restore();
        };

        render();
    }, [multiplier, isCrashed]);

    return (
        <canvas
            ref={canvasRef}
            width="600"
            height="300"
            style={{ backgroundColor: "transparent" }} // Ensure the canvas background is transparent
            className="canvas"
        ></canvas>
    );
};

export default GameCanvas;
