import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import GameCanvas from './GameCanvas';
import Sidebar from './Sidebar';
import PlayerManager from './PlayerManager';
import { FaSignOutAlt } from 'react-icons/fa'; // Importing the logout icon
import './App.css';

const socket = io('https://rocketaviator.com'); // Ensure HTTPS is used

const AviatorGame = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null); // Reference to control the video

  const [multiplier, setMultiplier] = useState(1.0);
  const [isCrashed, setIsCrashed] = useState(false);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    // Socket event listeners
    socket.on('countdownUpdate', (timeLeft) => {
      setCountdown(timeLeft);
      setIsGameRunning(false);
    });

    socket.on('multiplierUpdate', (newMultiplier) => {
      setMultiplier(newMultiplier);
      setIsGameRunning(true);
      setIsCrashed(false);
      setCountdown(null);
    });

    socket.on('gameCrashed', (crashMultiplier) => {
      setIsCrashed(true);
      setIsGameRunning(false);
      setHistory((prevHistory) => [crashMultiplier, ...prevHistory.slice(0, 19)]);
    });

    return () => {
      socket.off('countdownUpdate');
      socket.off('multiplierUpdate');
      socket.off('gameCrashed');
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (isCrashed) {
        videoRef.current.pause(); // Pause the video when game crashes
        videoRef.current.currentTime = 0; // Reset video time to 0
      } else if (isGameRunning) {
        videoRef.current.play(); // Play the video when game starts
      } else {
        videoRef.current.load(); // Reload the video during countdown
      }
    }
  }, [isCrashed, isGameRunning]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="app-container">
      {/* Background video */}
      <video
        ref={videoRef}
        className="background-video"
        src="/videos/rocket.mp4"
        autoPlay
        muted
        loop={false} // Stops playback when the game crashes
      />

      {/* Floating logout button with icon */}
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt size={24} /> {/* Logout icon */}
      </button>

      <div className="main-content">
        <Sidebar history={history} />
        <div className="game-section">
          <GameCanvas multiplier={multiplier} isCrashed={isCrashed} />
          <div className="game-status">
            {isGameRunning ? (
              <p style={{ fontSize: '48px', color: 'yellow', textAlign: 'center', fontWeight: 'bold' }}>
                 {multiplier.toFixed(2)}x
              </p>
            ) : (
              <p style={{ fontSize: '48px', color: 'yellow', textAlign: 'center', fontWeight: 'bold' }}>
                Next in: {countdown}s
              </p>
            )}
          </div>
          <PlayerManager
            multiplier={multiplier}
            gameStatus={isGameRunning ? 'Playing' : 'Idle'}
            countdown={countdown}
          />
        </div>
      </div>
    </div>
  );
};

export default AviatorGame;
