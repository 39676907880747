// src/Sidebar.js
import React from 'react';
import './App.css';

const Sidebar = ({ history }) => {
    return (
        <div className="sidebar">
            <h3>Game History</h3>
            <ul>
                {history.map((crash, index) => (
                    <li key={index}>: {crash.toFixed(2)}x</li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
