import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlayerPanel from './PlayerPanel';
import { io } from 'socket.io-client';

const api = axios.create({
    baseURL: 'https://rocketaviator.com/api',
    headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

const socket = io('https://rocketaviator.com', {
    auth: { token: localStorage.getItem('token') },
});

const PlayerManager = () => {
    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);
    const [gameStatus, setGameStatus] = useState('Waiting');
    const [multiplier, setMultiplier] = useState(1);

    const fetchPlayers = async () => {
        try {
            const response = await api.get('/get-players');
            setPlayers(response.data);
        } catch (err) {
            console.error('Error fetching players:', err.response?.data || err.message);
            setError('Failed to load player data.');
        }
    };

    useEffect(() => {
        fetchPlayers();

        socket.on('countdownUpdate', () => {
            setGameStatus('Countdown');
            setMultiplier(1);
        });

        socket.on('multiplierUpdate', (newMultiplier) => {
            setGameStatus('Playing');
            setMultiplier(newMultiplier);
        });

        socket.on('gameCrashed', () => {
            setGameStatus('Waiting');
            fetchPlayers();
        });

        socket.on('playerUpdated', (updatedPlayer) => {
            setPlayers((prevPlayers) =>
                prevPlayers.map((player) =>
                    player.player_id === updatedPlayer.playerId
                        ? {
                              ...player,
                              balance: updatedPlayer.balance,
                              status: updatedPlayer.status,
                              win: updatedPlayer.win,
                          }
                        : player
                )
            );
        });

        return () => {
            socket.off('countdownUpdate');
            socket.off('multiplierUpdate');
            socket.off('gameCrashed');
            socket.off('playerUpdated');
        };
    }, []);

    const placeBet = async (playerId, betAmount) => {
        if (gameStatus !== 'Countdown') {
            setError('');
            return;
        }
        try {
            const response = await api.post('/bet/place-bet', { playerId, betAmount });
            if (response.data.success) {
                setPlayers((prevPlayers) =>
                    prevPlayers.map((player) =>
                        player.player_id === playerId
                            ? { ...player, balance: response.data.balance, status: 'Bet Placed', betAmount }
                            : player
                    )
                );
            }
        } catch (err) {
            console.error('', err.response?.data || err.message);
            setError('');
        }
    };

    const cashOut = async (playerId) => {
        try {
            const response = await api.post('/bet/cash-out', { playerId, multiplier });
            if (response.data.success) {
                setPlayers((prevPlayers) =>
                    prevPlayers.map((player) =>
                        player.player_id === playerId
                            ? {
                                  ...player,
                                  balance: response.data.balance,
                                  status: 'Cashed',
                                  win: response.data.win,
                              }
                            : player
                    )
                );
            }
        } catch (err) {
            console.error('', err.response?.data || err.message);
            setError('');
        }
    };

    return (
        <div className="player-panels">
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {players.map((player, index) => (
            <PlayerPanel
                key={player.player_id}
                player={player}
                placeBet={(betAmount) => placeBet(player.player_id, betAmount)}
                cashOut={() => cashOut(player.player_id)}
                gameStatus={gameStatus}
                multiplier={multiplier}
                index={index}
            />
        ))}
    </div>
    );
};

export default PlayerManager;
