import React, { useState, useMemo, useEffect } from 'react';

const PlayerPanel = React.memo(({ player, placeBet, cashOut, gameStatus, multiplier, index, resetGame }) => {
    const [betAmount, setBetAmount] = useState(() => {
        // Retrieve the saved bet amount from localStorage for this player
        return localStorage.getItem(`betAmount_${player.player_name}`) || '';
    });
    const [finalWin, setFinalWin] = useState(0);
    const [balanceBeforeCashout, setBalanceBeforeCashout] = useState(player.balance || 0);

    // Save bet amount to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem(`betAmount_${player.player_name}`, betAmount);
    }, [betAmount, player.player_name]);

    // Calculate potential win when bet is placed
    const potentialWin = useMemo(() => {
        if (player.status === 'Bet Placed' && player.betAmount) {
            return player.betAmount * multiplier;
        }
        return 0;
    }, [player.status, player.betAmount, multiplier]);

    // Update final win and balance after cashing out
    useEffect(() => {
        if (player.status === 'Cashed' && potentialWin > 0) {
            setFinalWin(potentialWin); // Set final win after cashout
            setBalanceBeforeCashout(player.balance || 0); // Keep the balance before cashout for correct total
        }
    }, [player.status, potentialWin, player.balance]);

    // Reset final win and balance on game restart
    useEffect(() => {
        if (resetGame) {
            setFinalWin(0); // Reset final win
            setBalanceBeforeCashout(player.balance || 0); // Reset to initial balance
        }
    }, [resetGame, player.balance]);

    // Delay "crushed" state when the game ends
    const [showCrushed, setShowCrushed] = useState(false);
    useEffect(() => {
        if (gameStatus === 'Ended' && player.status !== 'Cashed' && player.status !== 'Failed') {
            const timer = setTimeout(() => {
                setShowCrushed(true); // Mark as "crushed" after the delay
            }, 1000); // 1-second delay before changing the state
            return () => clearTimeout(timer); // Clean up the timeout
        }
    }, [gameStatus, player.status]);

    // Determine the background color for the player panel based on status
    const getStatusColor = useMemo(() => {
        if (player.status === 'Bet Placed') {
            return '#00BFFF'; // Blue for Bet Placed
        }
        if (player.status === 'Cashed') {
            return '#32CD32'; // Green for Cashed
        }
        if (player.status === 'Failed') {
            return '#FF0000'; // Red for Failed
        }
        if (showCrushed) {
            return '#FF0000'; // Red for Crushed
        }const colors = ['#808080', '#000000', '#8B4513', '#8A2BE2', '#FFA500', '#FF4500', '#800080', '#FF6347', '#D2691E', '#DC143C'];
        return colors[index % colors.length]; // Use different colors for each player
    }, [player.status, showCrushed, index]);

    // Handle panel click for placing bet or cashing out
    const handlePanelClick = () => {
        if (player.status === 'Idle' && betAmount > 0 && gameStatus !== 'Running') {
            placeBet(parseFloat(betAmount));
            setBetAmount(''); // Optionally reset betAmount if bet is placed
        } else if (player.status === 'Bet Placed') {
            cashOut();
        }
    };

    const cashOutCelebration = player.status === 'Cashed' ? 'celebrate-cashout' : '';

    return (
        <div
            className={`player-panel ${cashOutCelebration}`}
            style={{
                backgroundColor: getStatusColor,
                color: '#fff',
                fontSize: '16px',
                padding: '8px',
                borderRadius: '8px',
                width: '300px',
                margin: '5px',
                boxSizing: 'border-box',
                textAlign: 'center',
                height: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            onClick={handlePanelClick}
        >
            {/* Styled player name in the top-left corner */}
            <div
                style={{
                    fontSize: '48px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    textAlign: 'left',
                    paddingLeft: '15px',
                    color: '#fff', // Removed golden color
                }}
            >
                {player.player_name}
            </div>

            <p style={{ fontSize: '14px', margin: '4px 0' }}>
               Ksh<span className="large-text">{parseFloat(balanceBeforeCashout + finalWin).toFixed(2)}</span>
            </p>

            {/* Always reserve space for the "Win" section */}
            <div style={{ minHeight: '30px', marginBottom: '6px' }}>
                {player.status === 'Bet Placed' || player.status === 'Cashed' ? (
                    <p style={{ fontSize: '14px', margin: '4px 0' }}>
                        Win{' '}
                        <span className="large-text">{finalWin > 0 ? finalWin.toFixed(2) : potentialWin.toFixed(2)}</span>
                    </p>
                ) : null}
            </div>

            {/* Always editable input for Bet Amount */}
            <input
                type="number"
                step="10"
                value={betAmount}
                onChange={(e) => setBetAmount(e.target.value)}
                placeholder="Enter Wager"
                style={{
                    color: '#000',
                    width: '100%',
                    maxWidth: '280px',
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    padding: '6px',
                    borderRadius: '5px',
                    marginTop: '6px',
                }}
                disabled={gameStatus === 'Running'} // Prevent bet submission during the game
            />

            {/* Display final win below balance */}
            {finalWin > 0 && gameStatus !== 'Running' && player.status === 'Cashed' && (
                <div className="final-win-display">
                    <h4 style={{ fontSize: '16px', color: '#fff' }}>Final Win:</h4>
                    <p style={{ fontSize: '16px' }}>
                        Ksh <span>{finalWin.toFixed(2)}</span>
                    </p>
                </div>
            )}
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.player.balance === nextProps.player.balance &&
        prevProps.player.status === nextProps.player.status &&
        prevProps.player.betAmount === nextProps.player.betAmount &&
        prevProps.multiplier === nextProps.multiplier &&
        prevProps.gameStatus === nextProps.gameStatus
    );
});

export default PlayerPanel;
